<template>
    <div v-if="visible" class="modal" role="dialog" style="display: block;">
        <div ref="modal" class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <header id="modal-primary___BV_modal_header_" class="modal-header">
                    <h5 id="modal-primary___BV_modal_title_" class="modal-title">
                        {{ title }}
                    </h5>
                    <button type="button" aria-label="Close" class="close" @click="$emit('close')">
                        ×
                    </button>
                </header>
                <div class="modal-body">
                    <p>{{ text }}</p>
                </div>
                <footer class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="$emit('close')">
                        {{ closeButtonText }}
                    </button>
                    <button type="button" class="btn btn-primary" :disabled="loading" @click="$emit('confirmed')">
                        {{ loading ? confirmLoadingText : confirmButtonText }}
                    </button>
                </footer>

            </div>
        </div>
    </div>
</template>

<style>
.modal {
    background: rgba(0, 0, 0, 0.3);
}
</style>

<script>
export default {
    props: {
        text: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: 'Confirm',
        },
        confirmLoadingText: {
            type: String,
            default: 'Loading...',
        },
        confirmButtonText: {
            type: String,
            default: 'Yes',
        },
        closeButtonText: {
            type: String,
            default: 'Cancel',
        },
        visible: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disableEnterConfirm: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        if (!this.disableEnterConfirm) {
            window.addEventListener('keyup', this.handleKeyEvent)
        }
    },
    methods: {
        handleKeyEvent(event) {
            if (this.visible) {
                if (event.keyCode === 27) {
                    this.$emit('close')
                }
                if (event.keyCode === 13) {
                    this.$emit('confirmed')
                }
            }
        },
    },
}
</script>
