<template>
    <div>
        <b-card>
            <XcTable
                ref="groupTable"
                endpoint="user-groups"
                :columns="tableColumns"
                :buttons="tableButtons"
                :records-per-page="100"
                :search-enabled="true"
            />
        </b-card>

        <modal-confirm-vuexy
            modal-id="deleteGroup"
            reference="deleteGroup"
            text="Are you sure you want to delete this user group?"
            title="Delete user group"
            :ok-function="deleteGroup"
        />
        <!--        <div class="m-content">-->
        <!--            &lt;!&ndash;begin::Portlet&ndash;&gt;-->
        <!--            <div class="m-portlet">-->
        <!--                <div class="m-portlet__body">-->
        <!--                    <XcTable-->
        <!--                        ref="groupTable"-->
        <!--                        endpoint="user-groups"-->
        <!--                        :columns="tableColumns"-->
        <!--                        :buttons="tableButtons"-->
        <!--                    />-->
        <!--                    <modal-confirm-->
        <!--                        :visible="deleteModalVisible"-->
        <!--                        text="Are you sure you want to delete this user group?"-->
        <!--                        title="Delete user group?"-->
        <!--                        :loading="deleteModalLoading"-->
        <!--                        @confirmed="deleteGroup"-->
        <!--                        @close="deleteModalClosed"-->
        <!--                    />-->
        <!--                    &lt;!&ndash;end: Datatable &ndash;&gt;-->
        <!--                </div>-->
        <!--            </div>-->
        <!--            &lt;!&ndash;end::Portlet&ndash;&gt;-->
        <!--        </div>-->
    </div>
</template>

<script>
import XcTable from '@/components/Misc/XcTable.vue'
import XcTableHelper from '@/components/Misc/XcTableHelper'
import ModalConfirm from '@/components/Modals/ModalConfirm.vue'
import Axios from 'axios'
import {
    BCard,
} from 'bootstrap-vue'
import ModalConfirmVuexy from '@/components/Modals/ModalConfirmVuexy'

export default {
    name: 'settings.user-groups',
    components: {
        // eslint-disable-next-line vue/no-unused-components
        XcTable,
        ModalConfirmVuexy,
        BCard,
    },
    data() {
        return {
            tableButtons: [
                {
                    class: 'btn btn-primary',
                    icon: 'fa-plus',
                    label: 'Add new group',
                    callback: this.addGroup,
                },
            ],
            tableColumns: [
                {
                    type: XcTableHelper.TYPE_TEXT,
                    title: 'ID',
                    name: 'id',
                    field: 'id',
                    label: 'ID',
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'name',
                    title: 'Name',
                    field: 'name',
                    label: 'Name',
                    sortByDefault: XcTableHelper.ORDER_BY_ASC,
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'user_count',
                    title: 'Member Count',
                    field: 'user_count',
                    label: 'Member Count',
                    disableSort: true,
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'created_at',
                    title: 'Created at',
                    field: 'created_at',
                    label: 'Created at',
                },
                {
                    type: XcTableHelper.TYPE_BUTTONS,
                    disableSort: true,
                    title: '',
                    field: 'action',
                    label: 'Action',
                    buttons: [
                        {
                            class: 'mr-50',
                            icon: 'Edit2Icon',
                            label: 'Edit',
                            callback: this.editGroup,
                        },
                        {
                            class: 'mr-50',
                            icon: 'TrashIcon',
                            label: 'Delete',
                            callback: this.deleteGroupConfirm,
                            shouldShowButton: row => row.is_deletable.text === 1,
                        },
                    ],
                },
            ],
            groupToDelete: 0,
        }
    },
    methods: {
        addGroup() {
            this.$router.push({ name: 'settings.user-groups.create' })
        },
        deleteGroupConfirm(id) {
            this.groupToDelete = id
            this.$bvModal.show('deleteGroup')

        },
        editGroup(id) {
            this.$router.push({ name: 'settings.user-groups.edit', params: { id } })
        },
        deleteGroup() {
            this.deleteModalLoading = true
            new Promise((resolve, reject) => {
                Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}user-groups/delete`, data: { id: this.groupToDelete }, method: 'DELETE' })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }).then(() => {
                this.$refs.groupTable.loadData()
            })
        },
    },
}
</script>
